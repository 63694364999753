import React, { FC, useEffect, useState } from 'react';
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Scatter,
  Dot,
  DotProps,
} from 'recharts';
import styles from './ChartWeather.module.css';
import { OperationMSTypes } from '../../../types/definitions';
import GenericFallback from '../../boundaries/operations/GenericErrorBoundary';
import withErrorBoundary from '../../boundaries/operations/withErrorBoundary.hook';

type WeatherDataType = {
  name: string;
  date: string;
  dateLabel: string;
  Precipitacao: number;
  Temperatura: number;
  Tempo: number;
  Geada?: any;
  Tempestade?: any;
};

const RenderDot: FC<DotProps> = ({ cx, cy, fill, r }) => {
  return <Dot cx={cx} cy={cy} fill={fill} r={r} />;
};

const normalizeWeatherConstant: Record<number, string> = {
  0: 'Claro',
  1: 'Tempo bom',
  2: 'Parcialmente nublado',
  3: 'Nublado',
  45: 'Garoa densa',
  48: 'Garoa densa',
  51: 'Garoa leve',
  53: 'Garoa moderada',
  55: 'Garoa densa',
  56: 'Garoa densa',
  57: 'Garoa densa',
  61: 'Chuva leve',
  63: 'Chuva moderada',
  65: 'Chuva pesada',
  66: 'Garoa densa',
  67: 'Garoa densa',
  71: 'Geada',
  73: 'Geada',
  75: 'Geada',
  77: 'Granizo',
  80: 'Chuva leve',
  81: 'Geada',
  82: 'Geada',
  85: 'Geada',
  86: 'Neve pesada',
  95: 'Trovoada leve ou moderada',
  96: 'Trovoada com granizo leve',
  99: 'Trovoada com granizo pesado',
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: any;
  snowData?: any;
  thunderStormData?: any;
  hailData?: any;
}

const CustomTooltip = ({
  active,
  payload,
  snowData,
  thunderStormData,
  hailData,
}: CustomTooltipProps) => {
  if (!active || !payload || !payload.length) return null;

  const { date, Tempo, Precipitacao, Temperatura } = payload[0].payload;
  const Geada = snowData.find((item) => item.date === date)?.Geada;
  const Tempestade = thunderStormData.find(
    (item) => item.date === date
  )?.Tempestade;

  const formattedDate = date.split('-').reverse().join('/');

  return (
    <div className={styles.containerTooltip}>
      <p>
        Data: <b>{formattedDate}</b>
      </p>
      <p>
        Tempo: <b>{normalizeWeatherConstant[Tempo]}</b>
      </p>
      <p style={{ color: '#053A65' }}>
        Precipitação (mm): <b>{Precipitacao}</b>
      </p>
      <p style={{ color: '#FF0E0E' }}>
        Temperatura (°C): <b>{Temperatura}</b>
      </p>
      {snowData.some((item) => item.Geada !== 0 && item.Geada) && (
        <p style={{ color: '#9999ff' }}>
          Geada (mm):{' '}
          <b>{Geada !== undefined ? (Geada * 1000).toFixed(2) : 0}</b>
        </p>
      )}
      {hailData.some((item) => item.Granizo !== 0 && item.Granizo) && (
        <p style={{ color: '#9999ff' }}>
          Granizo (mm):{' '}
          <b>{Geada !== undefined ? (Geada * 1000).toFixed(2) : 0}</b>
        </p>
      )}
      {thunderStormData.some(
        (item) => item.Tempestade !== 0 && item.Tempestade
      ) && (
        <p style={{ color: '#ff9999' }}>
          Tempestade (km/h): <b>{Tempestade !== undefined ? Tempestade : 0}</b>
        </p>
      )}
    </div>
  );
};

type ChartWeatherProps = {
  data?: OperationMSTypes.ById['glebes'][number]['weather'];
  dataWeatherGlobal?: any;
  setDataWeatherGlobal?: any;
};

function ChartWeather(props: ChartWeatherProps) {
  const { data, dataWeatherGlobal, setDataWeatherGlobal }: ChartWeatherProps =
    props;

  if (!data) return <>Dados de clima indisponíveis.</>;

  const formattedWeatherData: WeatherDataType[] = data.daily.time
    .filter((_, index) => !!data.daily.weathercode[index])
    .map(
      (date, index) =>
        ({
          name: date,
          date: date,
          dateLabel: date.split('-').reverse().join('/'),
          Precipitacao: data.daily.precipitation_sum[index],
          Temperatura: data.daily.temperature_2m_mean[index],
          Tempo: data.daily.weathercode[index],
        } as WeatherDataType)
    );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [weatherData, setWeatherData] = useState<WeatherDataType[]>([]);

  const thunderStorm: WeatherDataType[] = weatherData?.filter((weather) =>
    [99, 96, 95].includes(weather.Tempo)
  );

  const snow: WeatherDataType[] = weatherData?.filter((weather) =>
    [86, 85, 82, 81, 75, 73, 71].includes(weather.Tempo)
  );

  const hail: WeatherDataType[] = weatherData?.filter((weather) =>
    [77].includes(weather.Tempo)
  );

  const dateInit = formattedWeatherData[0].date;
  const dateEnd = formattedWeatherData[formattedWeatherData.length - 1].date;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const fetchDataWeather = async () => {
      try {
        const response = await fetch(
          `https://archive-api.open-meteo.com/v1/archive?latitude=${data.latitude}&longitude=${data.longitude}&start_date=${dateInit}&end_date=${dateEnd}&daily=weather_code,temperature_2m_mean,snowfall_sum,precipitation_sum,wind_gusts_10m_max&timezone=${data.timezone}&elevation=${data.elevation}`
        );
        if (!response.ok) {
          throw new Error('Erro na requisição');
        }
        const dataResponse = await response.json();
        const formattedData = dataResponse.daily.time.map(
          (time: string, index: number) =>
            ({
              name: time,
              date: time,
              dateLabel: time.split('-').reverse().join('/'),
              Tempo: dataResponse.daily.weather_code[index],
              Geada: dataResponse.daily.snowfall_sum[index],
              Tempestade: dataResponse.daily.wind_gusts_10m_max[index],
              Precipitacao: dataResponse.daily.precipitation_sum[index],
              Temperatura: dataResponse.daily.temperature_2m_mean[index],
            } as WeatherDataType)
        );

        const mergedData = formattedWeatherData.map((weatherItem) => {
          const matchingItem = formattedData.find(
            (item) => item.date === weatherItem.date
          );
          if (matchingItem) {
            return {
              ...weatherItem,
              Geada: matchingItem.Geada,
              Tempo: matchingItem.Tempo,
              Tempestade: matchingItem.Tempestade,
            };
          } else {
            return weatherItem;
          }
        });

        setWeatherData(mergedData);
      } catch (error) {
        console.error(error);
      }
    };
    fetchDataWeather();
  }, []);

  const hailData = hail
    .filter((hail) => [77].includes(hail.Tempo))
    .map((hailDate) => {
      return {
        name: hailDate.name,
        date: hailDate.date,
        dateLabel: hailDate.dateLabel,
        Tempo: hailDate.Tempo,
        Granizo: hailDate.Geada,
      };
    });

  const snowData = snow
    .filter((snow) => [86, 85, 82, 81, 77, 75, 73, 71].includes(snow.Tempo))
    .map((snowDate) => {
      return {
        name: snowDate.name,
        date: snowDate.date,
        dateLabel: snowDate.dateLabel,
        Geada: snowDate.Geada,
        Tempo: snowDate.Tempo,
      };
    });

  const thunderStormData = thunderStorm
    .filter((thunder) => [95, 96, 99].includes(thunder.Tempo))
    .map((thunderDate) => {
      return {
        name: thunderDate.name,
        date: thunderDate.date,
        dateLabel: thunderDate.dateLabel,
        Tempo: thunderDate.Tempo,
        Tempestade: thunderDate.Tempestade,
      };
    });

  const mergedData = weatherData.map((weatherItem) => ({
    ...weatherItem,
    Geada:
      snowData.find((snowItem) => snowItem.date === weatherItem.date)?.Geada ||
      null,
    Tempestade:
      thunderStormData.find(
        (thunderItem) => thunderItem.date === weatherItem.date
      )?.Tempestade || null,
    Granizo:
      hailData.find((hailItem) => hailItem.date === weatherItem.date)
        ?.Granizo || null,
  }));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const hasChanged =
      JSON.stringify(dataWeatherGlobal.snow) !== JSON.stringify(snow) ||
      JSON.stringify(dataWeatherGlobal.snowData) !== JSON.stringify(snowData) ||
      JSON.stringify(dataWeatherGlobal.thunderStorm) !==
        JSON.stringify(thunderStorm) ||
      JSON.stringify(dataWeatherGlobal.thunderStormData) !==
        JSON.stringify(thunderStormData) ||
      JSON.stringify(dataWeatherGlobal.hail) !== JSON.stringify(hail) ||
      JSON.stringify(dataWeatherGlobal.hailData) !== JSON.stringify(hailData);

    if (hasChanged) {
      setDataWeatherGlobal({
        snow,
        snowData,
        thunderStorm,
        thunderStormData,
        hail,
        hailData,
      });
    }
  }, [snow, snowData, thunderStorm, thunderStormData, hail, hailData]);

  return (
    <div className={styles.containerRecharts}>
      <div className={styles.divRecharts}>
        <ResponsiveContainer
          width="100%"
          height="100%"
          minWidth={700}
          style={{ fontSize: '12px' }}
          className={styles.responsiveContainer}
        >
          <ComposedChart data={mergedData}>
            <Tooltip
              content={
                <CustomTooltip
                  snowData={snowData}
                  thunderStormData={thunderStormData}
                  hailData={hailData}
                />
              }
            />
            <CartesianGrid strokeDasharray="3 5" vertical={false} />
            <XAxis
              dataKey="dateLabel"
              minTickGap={20}
              style={{ fontSize: '12px' }}
            />
            <YAxis yAxisId="left" />
            <YAxis yAxisId="right" orientation="right" />
            <Area
              type="monotone"
              yAxisId="left"
              dataKey="Temperatura"
              name="Temperatura"
              stroke="#FF0E0E"
              fill="#FF0E0E33"
            />
            <Area
              type="monotone"
              yAxisId="right"
              dataKey="Precipitacao"
              name="Precipitação"
              stroke="#1F8EF1"
              fill="#1F8EF133"
            />
            {mergedData.some((item) => item.Geada !== 0 && item.Geada) && (
              <Scatter
                dataKey="Geada"
                name="Geada"
                fill="#9999ff"
                stroke="#9999ff"
                yAxisId="left"
                legendType="none"
                shape={<RenderDot fill={'#9999ff'} r={6} />}
              />
            )}
            {mergedData.some(
              (item) => item.Tempestade !== 0 && item.Tempestade
            ) && (
              <Scatter
                dataKey="Tempestade"
                name="Tempestade"
                fill="#ff9999"
                stroke="#ff9999"
                yAxisId="left"
                legendType="none"
                shape={<RenderDot fill={'#ff9999'} r={3.5} />}
              />
            )}
            {mergedData.some((item) => item.Granizo !== 0 && item.Granizo) && (
              <Scatter
                dataKey="Granizo"
                name="Granizo"
                fill="#90c6df"
                stroke="#90c6df"
                yAxisId="left"
                legendType="none"
                shape={<RenderDot fill={'#90c6df'} r={4} />}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
        <div>
          <p className={styles.legendTemp}>Temperatura (°C)</p>
          <p className={styles.legendPrec}>Precipitação (mm)</p>
        </div>
      </div>
    </div>
  );
}

export default withErrorBoundary(ChartWeather, {
  fallback: GenericFallback,
  fallbackMessage:
    'Não há dados de clima suficientes para gerar o gráfico de clima',
});
