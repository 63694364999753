import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Chart } from '../../components/Charts';
import { Filter } from '../../components/Filter';
import Icon from '../../components/Icon/Icon.component';
import Page from '../../layout/components/Page/Page.component';
import styles from './Dashboard.module.css';
import DashboardMS from '../../services/ms/DashboardMS.service';
import { DashboardMSTypes } from '../../types/definitions';
import toCurrency from '../../utils/toCurrency.util';
import toNumeric from '../../utils/toNumeric.util';
import { useSearchParams } from 'react-router-dom';
import useFilters from '../../hooks/useFilters.hook';

type DashboardData = {
  alerts: DashboardMSTypes.Alerts;
  hectares: DashboardMSTypes.Summary;
  totals: DashboardMSTypes.Totals;
  domains: DashboardMSTypes.Domains;
};

const initialState: DashboardData = {
  alerts: [],
  hectares: [],
  totals: {
    total_area: 0,
    total_operation: 0,
    total_operation_area: '',
    total_receivable: 0,
    total_receivable_area: 0,
    total_receivable_operation: 0,
  },
  domains: { alert_types: [], cities: [], cultures: [], ufs: [], cycles: [] },
};

export default function Dashboard(): JSX.Element {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<DashboardData>(initialState);
  const { filters, addFilter, clearFilters, applyFilters } = useFilters();

  let isLoading = useRef<boolean>(true);

  useEffect(() => {
    isLoading.current = true;

    async function fetchData() {
      const responses = await Promise.allSettled([
        DashboardMS.Summary(searchParams),
        DashboardMS.Alerts(searchParams),
        DashboardMS.Totals(searchParams),
        DashboardMS.Domains(),
      ]);

      responses.forEach((response, index) => {
        if (response.status !== 'fulfilled') {
          return;
        }
        if (response.value === false) {
          return;
        }

        if (index === 0) {
          setData((prev) => ({
            ...prev,
            hectares: response.value as DashboardMSTypes.Summary,
          }));
        } else if (index === 1) {
          setData((prev) => ({
            ...prev,
            alerts: response.value as DashboardMSTypes.Alerts,
          }));
        } else if (index === 2) {
          setData((prev) => ({
            ...prev,
            totals: response.value as DashboardMSTypes.Totals,
          }));
        } else if (index === 3) {
          setData((prev) => ({
            ...prev,
            domains: response.value as DashboardMSTypes.Domains,
          }));
        }
      });

      isLoading.current = false;
    }

    fetchData();
  }, [searchParams]);

  const [stateFilters, setStateFilters] = useState({
    culture_name: '',
    cycle: '',
    alert_type: '',
    city: '',
    uf: '',
    is_active: '',
  });

  function handleClearFilters() {
    clearFilters();
    setStateFilters({
      culture_name: '',
      cycle: '',
      alert_type: '',
      city: '',
      uf: '',
      is_active: '',
    });
  }

  const getAlertDescription = (type: string) => {
    const alertType = data.domains.alert_types.find(
      (alert) => alert.type === type
    );
    return alertType ? alertType.description : '';
  };

  const getUfName = (uf: string) => {
    const ufName = data.domains.ufs.find((u) => u.uf === uf);
    return ufName ? ufName.name : '';
  };

  const getFilteredCities = (uf: string) => {
    return data.domains.cities
      .filter((city) => city.uf === uf)
      .map((city) => city.city);
  };

  useEffect(() => {
    if (stateFilters.uf || stateFilters.uf === '') {
      addFilter('city', stateFilters.city);
    }
  }, [stateFilters.uf, addFilter]);

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const hasValues = Object.values(stateFilters).some((value) => value !== '');
    if (
      (hasValues === true && data.alerts.length) ||
      (hasValues === true && data.hectares.length)
    ) {
      setIsButtonEnabled(true);
    } else {
      setIsButtonEnabled(false);
    }
  }, [stateFilters]);

  return (
    <Page
      breadcrumbs={{
        icon: 'House',
        breadcrumbs: [{ label: 'Dashboard', href: '/', active: true }],
      }}
    >
      <Filter.Root>
        <Filter.Group>
          <Filter.Selector
            loading={isLoading.current}
            label="Cultura"
            itemsType="string"
            items={data.domains.cultures.map((culture) => culture.culture_name)}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              addFilter('culture_name', target.value);
              setStateFilters({ ...stateFilters, culture_name: target.value });
            }}
            value={filters['culture_name'] || 'Todos'}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="Ciclo"
            itemsType="string"
            items={data.domains.cycles.map((cycle) => cycle.cycle)}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              addFilter('cycle', target.value);
              setStateFilters({ ...stateFilters, cycle: target.value });
            }}
            value={filters['cycle'] || 'Todos'}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="Alerta"
            itemsType="object"
            itemLabelKey="description"
            itemValueKey="type"
            items={data.domains.alert_types}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              addFilter('alert_type', target.value);
              setStateFilters({ ...stateFilters, alert_type: target.value });
            }}
            value={getAlertDescription(filters['alert_type']) || 'Todos'}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="UF"
            itemsType="object"
            itemValueKey="uf"
            itemLabelKey="name"
            items={data.domains.ufs}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              addFilter('uf', target.value);
              setStateFilters({
                ...stateFilters,
                uf: target.value,
                city: '',
              });
            }}
            value={getUfName(filters['uf']) || 'Todos'}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="Cidade"
            itemsType="string"
            items={getFilteredCities(stateFilters.uf)}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              addFilter('city', target.value);
              setStateFilters({ ...stateFilters, city: target.value });
            }}
            value={stateFilters['city'] || ''}
          />
          <Filter.Selector
            loading={isLoading.current}
            label="Status"
            itemsType="string"
            items={['Finalizado', 'Vigente']}
            onClick={(event) => {
              const target = event.target as HTMLSelectElement;
              const newValue =
                target.value === 'Vigente'
                  ? 'true'
                  : target.value === 'Finalizado'
                  ? 'false'
                  : '';
              addFilter('is_active', newValue);
              setStateFilters({ ...stateFilters, is_active: newValue });
            }}
            value={
              filters['is_active'] === 'true'
                ? 'Vigente'
                : filters['is_active'] === 'false'
                ? 'Finalizado'
                : 'Todos'
            }
          />
        </Filter.Group>
        <Filter.Group type="flex">
          <Filter.Button
            label="Limpar filtros"
            variant="outline-secondary"
            iconName="Close"
            onClick={handleClearFilters}
          />
          <Filter.Button
            label="Filtrar"
            variant="filled-secondary"
            iconName="Filter"
            onClick={applyFilters}
            disabled={!isButtonEnabled}
            className={`${!isButtonEnabled && styles.disabledButton}`}
          />
        </Filter.Group>
      </Filter.Root>
      <section className={styles.container}>
        <article className={styles.charts}>
          <div className={styles.chart}>
            <div className={styles.description}>
              <p className={styles.title}>
                <b>Alertas</b>
              </p>
              {!data.alerts.length && !isLoading.current ? (
                <p className={styles.subtitle} style={{ marginTop: '5px' }}>
                  Não há operações cadastradas para visualização do gráfico de
                  alertas.
                </p>
              ) : (
                <p className={styles.subtitle} style={{ marginTop: '5px' }}>
                  Acompanhe todos os alertas relacionados ao plantio e
                  compliance das terras.
                </p>
              )}
            </div>
            {!data.alerts.length && !isLoading.current ? (
              <></>
            ) : (
              <div className={styles.chartWrapper}>
                <Chart.Alert data={data.alerts} />
              </div>
            )}
          </div>
          <div className={styles.chart}>
            <div className={styles.description}>
              <p className={styles.title}>
                <b>Hectares por cultura</b>
              </p>
              {!data.hectares.length && !isLoading.current ? (
                <p className={styles.subtitle} style={{ marginTop: '5px' }}>
                  Não há operações cadastradas para visualização do gráfico de
                  hectares.
                </p>
              ) : (
                <p className={styles.subtitle} style={{ marginTop: '5px' }}>
                  Acompanhe a quantidade de hectares por cultura.
                </p>
              )}
            </div>
            {!data.hectares.length && !isLoading.current ? (
              <></>
            ) : (
              <div className={styles.chartWrapper}>
                <Chart.Hectares data={data.hectares} />
              </div>
            )}
          </div>
        </article>
        <article className={styles.cards}>
          <aside className={styles.cardsWrapper}>
            <div className={styles.card}>
              <p className={styles.title}>
                <b>Total das informações</b>
              </p>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  <b>Total de operações</b>
                </p>
                <p className={styles.itemValue}>
                  <span>{toNumeric(data.totals.total_operation)}</span>
                  <Icon name="Files" />
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  <b>Total de hectares</b>
                </p>
                <p className={styles.itemValue}>
                  <span>{toNumeric(data.totals.total_area)} ha</span>
                  <Icon name="GridNine" />
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  <b>Total de recebíveis</b>
                </p>
                <p className={styles.itemValue}>
                  <span>{toCurrency(data.totals.total_receivable)}</span>
                  <Icon name="Money" />
                </p>
              </div>
            </div>
            <hr />
            <div className={styles.card}>
              <p className={styles.title}>
                <b>Média das informações</b>
              </p>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  <b>Área média por operações</b>
                </p>
                <p className={styles.itemValue}>
                  {data.totals.total_operation_area === 'NaN'
                    ? 0
                    : data.totals.total_operation_area}{' '}
                  ha
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  <b>Média de recebíveis por hectares</b>
                </p>
                <p className={styles.itemValue}>
                  {toCurrency(data.totals.total_receivable_area)}
                </p>
              </div>
              <div className={styles.item}>
                <p className={styles.itemTitle}>
                  <b>Média de recebíveis por operações</b>
                </p>
                <p className={styles.itemValue}>
                  {toCurrency(data.totals.total_receivable_operation)}
                </p>
              </div>
            </div>
          </aside>
        </article>
      </section>
    </Page>
  );
}
