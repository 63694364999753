import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useStateSelector from '../store/hooks/useStateSelector.hook';
import Cookies from 'js-cookie';
import AccountMS from '@spot-spotsat/services/ms/AccountMS.service';
import useActionsDispatcher from '@spot-spotsat/store/hooks/useActionsDispatcher.hook';
import useActionsSelector from '@spot-spotsat/store/hooks/useActionsSelector.hook';

export default function Middleware(): JSX.Element {
  const { status, value } = useStateSelector('auth');

  const dispatch = useActionsDispatcher();
  const { clear } = useActionsSelector('auth');

  useEffect(() => {
    async function fetchUser() {
      if (!value.user) return;

      const response = await AccountMS.ById(value.user.id);

      setTimeout(() => {
        if (response === false) {
          Cookies.remove(process.env.REACT_APP_COOKIE_KEY_TOKEN!);
          dispatch(clear());
        }
      }, 100);
    }

    fetchUser();
  }, [value.user]);

  const variant = 'spoteye';
  const currentHref = window.location.href;

  if (status === 'not-initialized' && (!value.token || !value.user)) {
    window.location.href = `${String(
      process.env.REACT_APP_URL_SPOTAUTH
    )}/login?redirectUrl=${currentHref}&variant=${variant}`;

    return <></>;
  }

  return <Outlet />;
}
